import React, { useState } from 'react';
import { getActiveContactId, getContacts, getConversations } from '../../redux/selectors/spiderTxt';
import { useSelector } from 'react-redux';
import { ContactType } from '../../types/spidertxt';

const ConversationHeaderCollapsible: React.FC = () => {
  const activeConversationId = useSelector(getActiveContactId);
  const contacts = useSelector(getContacts);
  const conversations = useSelector(getConversations);

  const activeContact = contacts.find(contact => contact.id === activeConversationId);
  const activeContactConversation = conversations.find(
    conversation => conversation.contactId === activeConversationId
  );

  if (activeContact === undefined || activeContactConversation === undefined) {
    return <></>;
  }
  const [isOpen, setIsOpen] = useState(
    activeContactConversation.messages.length > 0 ? false : true
  );
  const changeOpenState = () => {
    setIsOpen(!isOpen);
  };

  const groupMembers =
    activeContact.contactType == ContactType.GROUP
      ? activeContact.memberIds
          .map(memberId => contacts.find(contact => contact.id === memberId))
          .filter(contact => contact !== undefined)
          .sort(function(a, b) {
            return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
          })
      : undefined;

  return (
    <div className={`cs-expansion-panel ${isOpen ? 'cs-expansion-panel--open' : ''}`}>
      <div className="cs-expansion-panel__header">
        <div
          onClick={changeOpenState}
          className="cs-expansion-panel__icon"
          style={{
            transform: isOpen ? 'rotate(180deg)' : '',
            transition: 'transform 150ms ease'
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="chevron-down"
            className="svg-inline--fa fa-chevron-down fa-w-14 "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
            ></path>
          </svg>
        </div>
        <div className="cs-expansion-panel__title">{activeContact.displayName}</div>
      </div>
      <div className="cs-expansion-panel__content">
        <div className="cs-expansion-panel__content-centered">
          <div className="cs-expansion-panel__content_label">
            {activeContact.contactType == ContactType.GROUP && 'ORGANISATION'}

            {activeContact.contactType == ContactType.INDIVIDUAL &&
              `${activeContact.organisations.length} COMMON ORGANISATION${
                activeContact.organisations.length > 1 ? 'S' : ''
              }${activeContact.organisations.length > 5 ? ' (scroll to see all)' : ''}`}
          </div>
          <div className="cs-expansion-panel__content_value" style={{ maxHeight: '100px' }}>
            {activeContact.contactType == ContactType.GROUP
              ? activeContact.organisationName
              : structuredClone(activeContact.organisations)
                  .sort(function(a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                  })
                  .map(organisation => <div key={organisation}>{organisation}</div>)}
          </div>
          {groupMembers && (
            <>
              <div className="cs-expansion-panel__content_label">
                {`${groupMembers.length} `}
                MEMBERS{groupMembers.length > 10 && ' (scroll to see all)'}
              </div>
              <div className="cs-expansion-panel__content_value">
                {groupMembers.map(member => (
                  <div key={member.id}>{member.displayName}</div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConversationHeaderCollapsible;
